import { inject, Injectable, signal } from '@angular/core';
import {
  ArriveFreightStopCommand,
  DriverPayload,
  ReplaceScheduledJourneyDriverCommand,
  UploadFreightDocumentCommand,
  VehiclePayload,
  VerifyFreightStopGoodsInfoCommand,
} from '@okcargo/command-processor';
import {
  CriteriaPayload,
  DocumentMetadataFieldNames,
  DocumentMetadataResponse,
  FilterCombinator,
  FilterComparator,
  FilterPayload,
  FindFreightQuery,
  FreightFieldNames,
  OrderPayload,
  OrderType,
  SearchDocumentMetadataQuery,
  SearchFreightsWithPendingDocumentationQuery,
  SearchInProgressFreightsQuery,
  SearchNotInProgressFreightsQuery,
} from '@okcargo/query-processor';
import { BehaviorSubject, forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { AccountService } from 'src/app/account/application/account.service';
import { FreightCommandService } from '../infrastructure/freight-command.service';
import { FreightQueryService } from '../infrastructure/freight-query.service';
import {
  EFreightStatus,
  EStopStatus,
  getEmptyDefaultFreightBasic,
  getEmptyDefaultFreightDetail,
  ICoords,
  IDigitizedDeliveryNotes,
  IFreightBasic,
  IFreightDetail,
  IFreightVehicles,
  IStopDetail,
  IStopGoodsInfo,
} from './../domain/freight.model';

import { isAfter } from 'date-fns';
import { IAccount } from 'src/app/account/domain/account.model';
import { DownloadDocumentService } from 'src/app/common/infrastructure/download-document.service';
import { v4 as uuidv4 } from 'uuid';
import { transformToIFreightBasic, transformToIFreightDetail } from '../infrastructure/freight-mapper';
@Injectable({
  providedIn: 'root',
})
export class FreightService {
  private readonly freightQueryService = inject(FreightQueryService);
  private readonly freightCommandService = inject(FreightCommandService);
  private readonly downloadDocumentService = inject(DownloadDocumentService);
  private readonly accountService = inject(AccountService);

  private readonly page: number = 0;
  private readonly size: number = 300;

  private readonly _freightsInProgress$ = new BehaviorSubject<IFreightBasic[]>([]);
  private readonly _freightsFinished$ = new BehaviorSubject<IFreightBasic[]>([]);

  private readonly _freightstWithPendingDocumentation$ = new BehaviorSubject<IFreightBasic[]>([]);
  private readonly _freightstWithPendingDeliveryNoteInPaper$ = new BehaviorSubject<IFreightBasic[]>([]);

  public freightstWithPendingDocumentationLoading = signal(true);
  public freightsInProgressLoading = signal(true);
  public freightsFinishedLoading = signal(true);

  private readonly _lastRefreshFreightDetailTimestamp = signal<number>(Date.now());

  private account!: IAccount;

  constructor() {
    this.accountService.account$.subscribe((account) => {
      if (account) {
        this.account = account;
      }
    });
  }

  get freightsInProgress$(): Observable<IFreightBasic[]> {
    return this._freightsInProgress$.asObservable();
  }

  get freightsFinished$(): Observable<IFreightBasic[]> {
    return this._freightsFinished$.asObservable();
  }

  get freightstWithPendingDocumentation$(): Observable<IFreightBasic[]> {
    return this._freightstWithPendingDocumentation$.asObservable();
  }

  get freightstWithDeliveryNoteInPaper$(): Observable<IFreightBasic[]> {
    return this._freightstWithPendingDeliveryNoteInPaper$.asObservable();
  }

  // TODO use it in components template (freightDetail)
  public get lastRefreshFreightDetailTimestamp(): number {
    return this._lastRefreshFreightDetailTimestamp();
  }

  public refreshFreightsInprogress(): void {
    this.searchFreightsInprogress();
  }

  public refreshFreightsFinished(): void {
    this.searchFreightsFinished();
  }

  public refreshFreightstWithPendingDocumentation(): void {
    this.searchAllFreightsWithPendingDocumentation();
  }

  public refreshFreightDetail(): void {
    // Trigger/modifies the signal's content so that the component (they has
    // the freight id) makes the corresponding call in the constructor's effect()
    this._lastRefreshFreightDetailTimestamp.set(Date.now());
  }

  private searchFreightsInprogress(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.ASC,
          } as OrderPayload,
        ],
        // only freights assigned to company
        filters: [
          {
            combinator: FilterCombinator.NONE,
            field: FreightFieldNames.CARRIER_ID,
            comparator: FilterComparator.EQ,
            value: this.account.company.id,
            group: null,
          },
        ],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchInProgressFreightsQuery;

    from(this.freightQueryService.searchInProgressFreights(query))
      .pipe(
        map((freights) =>
          freights.map((freightBasicResponse): IFreightBasic => {
            const freight: IFreightBasic = getEmptyDefaultFreightBasic();
            return transformToIFreightBasic(freightBasicResponse, freight);
          }),
        ),
        // TODO Maybe this is a temporary solution because there were times when the status of the last stop was not consistent with that of the freight
        map((freights) => freights.filter((freight) => freight.status !== EFreightStatus.FINISHED)),
      )
      .subscribe((transformedFreights) => {
        this._freightsInProgress$.next(transformedFreights);
        this.freightsInProgressLoading.set(false);
      });

    // TODO add new property: isInteractive
    /*
    La idea es evitar el error de usuario y que no aparezcan botones de 'iniciar', 'parar',...
    en aquellas cargas con las que el usuario no debería interactuar.
    Por tanto, solo deben aparecer botones en:
    - Aquellas cargas cuya fecha de la primera parada sea anterior a hoy + 1 día
    Y, además:
    - Que no haya otra carga que esté iniciada (revisar estados de freight)

    Una vez mapeados todos los valores que llegan en freightBasicResponse, volver a recorrer para
    cambiar el estado de isInteractive en aquellas que sea necesario

    ¿Valor por defecto de isIntercative?

    */
  }

  private searchFreightsFinished(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.DESC,
          } as OrderPayload,
        ],
        // only freights assigned to company
        filters: [
          {
            combinator: FilterCombinator.NONE,
            field: FreightFieldNames.CARRIER_ID,
            comparator: FilterComparator.EQ,
            value: this.account.company.id,
            group: null,
          },
        ],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchNotInProgressFreightsQuery;

    from(this.freightQueryService.searchFinishedFreights(query))
      .pipe(
        map((freights) =>
          freights.map((freightBasicResponse): IFreightBasic => {
            const freight: IFreightBasic = getEmptyDefaultFreightBasic();
            return transformToIFreightBasic(freightBasicResponse, freight);
          }),
        ),
      )
      .subscribe((transformedFreights) => {
        this._freightsFinished$.next(transformedFreights);
        this.freightsFinishedLoading.set(false);
      });
  }

  private searchAllFreightsWithPendingDocumentation(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.ASC,
          } as OrderPayload,
        ],
        filters: [],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchFreightsWithPendingDocumentationQuery;

    const afterDate = new Date(2024, 2, 1); // 1 de marzo de 2024

    from(this.freightQueryService.searchFreightsWithPendingDocumentation(query))
      .pipe(
        map((freights): IFreightBasic[] =>
          freights
            .filter(
              (freightBasicResponse) =>
                freightBasicResponse.shipperId === 50 && // delete when the shipper is not OkCargo
                isAfter(freightBasicResponse.finishedAt, afterDate), // remove when is before 1 march 2024
            )
            .map((freightBasicResponse): IFreightBasic => {
              const freight: IFreightBasic = getEmptyDefaultFreightBasic();
              return transformToIFreightBasic(freightBasicResponse, freight);
            }),
        ),
        map((transformedFreights) => {
          const freightsWithPendingDeliveryNoteInPaper = transformedFreights.filter(
            (freight) => freight.deliveryNoteInPaperReceived === false,
          );
          const freightsWithPendingDigitizedDocumentation = transformedFreights.filter(
            (freight) => freight.hasEnoughDocs === false,
          );
          return {
            freightsWithPendingDeliveryNoteInPaper,
            freightsWithPendingDigitizedDocumentation,
          };
        }),
      )
      .subscribe(({ freightsWithPendingDeliveryNoteInPaper, freightsWithPendingDigitizedDocumentation }) => {
        this._freightstWithPendingDeliveryNoteInPaper$.next(freightsWithPendingDeliveryNoteInPaper);
        this._freightstWithPendingDocumentation$.next(freightsWithPendingDigitizedDocumentation);
        this.freightstWithPendingDocumentationLoading.set(false);
      });
  }

  public getFreightDetail$(id: string): Observable<IFreightDetail> {
    const query = {
      id: id,
    } as FindFreightQuery;

    return from(this.freightQueryService.findFreight(query)).pipe(
      switchMap((freightDetailedResponse): Observable<IFreightDetail> => {
        const freight: IFreightDetail = getEmptyDefaultFreightDetail();
        const transformedFreight = transformToIFreightDetail(freightDetailedResponse, freight);

        if (freightDetailedResponse.numberOfDeliveryNotes > 0) {
          return this.searchFreightDetailDocuments$(freightDetailedResponse.id).pipe(
            map((documents) => {
              // TODO review this, mapper necessary??? probably yes
              const deliveryNotes = documents.filter((document) => document.type === 'DELIVERY_NOTE');
              transformedFreight.digitizedDeliveryNotes = deliveryNotes as unknown as IDigitizedDeliveryNotes[];
              return transformedFreight;
            }),
          );
        } else {
          return of(transformedFreight);
        }
      }),
    );
  }

  private searchFreightDetailDocuments$(freightId: string): Observable<DocumentMetadataResponse[]> {
    const query = {
      criteriaPayload: {
        order: [],
        filters: [
          {
            field: DocumentMetadataFieldNames.AGGREGATE_ID,
            comparator: FilterComparator.EQ,
            value: freightId,
            combinator: FilterCombinator.NONE,
          } as FilterPayload,
        ], // FilterPayload[]; create a filter by history or in-progress
        pageIndex: 0,
        pageSize: 30,
      } as unknown as CriteriaPayload,
    } as SearchDocumentMetadataQuery;

    return from(this.freightQueryService.searchDocumentMetadata(query)).pipe(
      switchMap((documents: DocumentMetadataResponse[]) => {
        const documentObservables = documents.map((document) => {
          const url = this.downloadDocumentService.getDownloadDocumentURL(document);
          return of({ ...document, url });
        });
        return forkJoin(documentObservables);
      }),
    );
  }

  // In old app:  async markDepartureDateTime(stop: FreightStopResponse) { ...
  public async modifyStopStatus(
    stop: IStopDetail,
    freight: IFreightDetail,
    nextStopStatus: EStopStatus,
    coords?: ICoords,
  ): Promise<void> {
    // const point = this.getPoint(); // TODO not in use in old app
    // old markDepartureDateTime() and markArrivalDateTime()

    const trailerVehicle: IFreightVehicles | null =
      freight.vehicles?.find((vehicle) => vehicle.type === 'TRAILER') || null;
    const motorizedVehicle: IFreightVehicles | null =
      freight.vehicles?.find((vehicle) => vehicle.type !== 'TRAILER') || null;

    const command = {
      freightId: stop.freightId,
      stopId: stop.id,
      driverPayload: {} as DriverPayload, // TODO for now it is necessary to send it even if it is empty
      // TODO DriverPayload Set info in server
      // TODO add driverAppPointPayload
    } as ArriveFreightStopCommand; // TODO check this, same interface in both cases? It comes from old app...

    if (coords) {
      command.driverAppPointPayload = coords;
    }

    if (motorizedVehicle) {
      command.motorizedVehiclePayload = {
        id: motorizedVehicle.id,
        partyId: motorizedVehicle.partyId,
        plateNumber: motorizedVehicle.plate,
      } as VehiclePayload;
    }
    if (trailerVehicle) {
      command.trailerVehiclePayload = {
        id: trailerVehicle.id,
        partyId: trailerVehicle.partyId,
        plateNumber: trailerVehicle.plate,
      } as VehiclePayload;
    }

    if (nextStopStatus === EStopStatus.STARTED) {
      this.freightCommandService.arriveToFreightStop(command).then(
        () => {
          // TODO google analytics
        },
        async (error: any) => {
          // TODO implement this
          console.warn(error);
        },
      );
    } else if (nextStopStatus === EStopStatus.FINISHED) {
      this.freightCommandService.departFromFreightStop(command).then(
        () => {
          // TODO google analytics
        },
        async (error: any) => {
          // TODO implement this
          console.warn(error);
        },
      );
    }
  }

  // In old app:  async sendData() { ... in freight-data.component.ts
  public async sendStopGoodsInfo(command: IStopGoodsInfo): Promise<void> {
    this.freightCommandService.verifyFreightStopGoodsInfo(command as VerifyFreightStopGoodsInfoCommand).then(
      () => {},
      async (error: any) => {
        // TODO implement this
        console.warn(error);
      },
    );
  }

  public async uploadFreightDeliveryNote(fileInput: File, stopId: string, freightId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = async (readerEvent: ProgressEvent<FileReader>): Promise<void> => {
        const fileData = (readerEvent.target as FileReader).result as string;
        const fileType = fileInput.type;
        const fileName = fileInput.name;
        const extension = fileName.substring(fileName.lastIndexOf('.'));
        // const extension = '.pdf'; // TODO always pdf? or maybe convert to PDF
        const base64Data = fileData.substring(fileData.indexOf(',') + 1);
        const uploadFileName = 'order_' + this.getFileFormattedDate(new Date()) + extension;

        const command: UploadFreightDocumentCommand = {
          data: base64Data,
          contentType: fileType, // 'application/pdf' ¿?¿?¿?
          description: '',
          documentId: uuidv4(),
          freightId: freightId,
          name: uploadFileName,
          type: 'DELIVERY_NOTE', // DocumentType.DELIVERY_NOTE, // TODO: enum?
          freightStopId: stopId,
        } as unknown as UploadFreightDocumentCommand;

        try {
          await this.freightCommandService.uploadFreightDocument(command);
          resolve(fileName);
        } catch (error) {
          console.warn('File not saved ' + JSON.stringify(error));
          // TODO: display message
          reject(error);
        }
      };

      reader.onerror = (error): void => {
        reject(error);
      };

      reader.readAsDataURL(fileInput);
    });
  }

  // TODO move to other service ???
  public async setDriverAssignation(driver: any): Promise<void> {
    this.freightCommandService.replaceScheduledJourneyDriver(driver as unknown as ReplaceScheduledJourneyDriverCommand);
  }

  /// TODO move to service or another
  private getFileFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}${month}${day}_${hours}${minutes}`;
  }
}
