import { Injectable } from '@angular/core';
import {
  DocumentMetadataResponse,
  FindFreightQuery,
  FindFreightQueryHandler,
  FreightBasicResponse,
  FreightDetailedResponse,
  SearchDocumentMetadataQuery,
  SearchDocumentMetadataQueryHandler,
  SearchFreightsWithPendingDocumentationQuery,
  SearchFreightsWithPendingDocumentationQueryHandler,
  SearchInProgressFreightsQuery,
  SearchInProgressFreightsQueryHandler,
  SearchNotInProgressFreightsQuery,
  SearchNotInProgressFreightsQueryHandler,
} from '@okcargo/query-processor';
import { Observable, from } from 'rxjs';
import { BaseQueryRepository } from 'src/app/common/infrastructure/query.repository';

@Injectable({
  providedIn: 'root',
})
export class FreightQueryService {
  private readonly searchInProgressFreightsQueryHandler: SearchInProgressFreightsQueryHandler;
  private readonly searchNotInProgressFreightsQueryHandler: SearchNotInProgressFreightsQueryHandler;
  private readonly findFreightQueryHandler: FindFreightQueryHandler;
  private readonly searchDocumentMetadataQueryHandler: SearchDocumentMetadataQueryHandler;
  private readonly searchFreightsWithPendingDocumentationQueryHandler: SearchFreightsWithPendingDocumentationQueryHandler;

  constructor(private readonly queryRepository: BaseQueryRepository) {
    this.searchInProgressFreightsQueryHandler = new SearchInProgressFreightsQueryHandler(queryRepository);
    this.searchNotInProgressFreightsQueryHandler = new SearchNotInProgressFreightsQueryHandler(queryRepository);
    this.findFreightQueryHandler = new FindFreightQueryHandler(queryRepository);
    this.searchDocumentMetadataQueryHandler = new SearchDocumentMetadataQueryHandler(queryRepository);
    this.searchFreightsWithPendingDocumentationQueryHandler = new SearchFreightsWithPendingDocumentationQueryHandler(
      queryRepository,
    );
  }

  public searchInProgressFreights(query: SearchInProgressFreightsQuery): Promise<FreightBasicResponse[]> {
    return this.searchInProgressFreightsQueryHandler.handle(query);
  }

  public searchFinishedFreights(query: SearchNotInProgressFreightsQuery): Promise<FreightBasicResponse[]> {
    return this.searchNotInProgressFreightsQueryHandler.handle(query);
  }

  public searchFreightsWithPendingDocumentation(
    query: SearchFreightsWithPendingDocumentationQuery,
  ): Promise<FreightBasicResponse[]> {
    return this.searchFreightsWithPendingDocumentationQueryHandler.handle(query);
  }

  public findFreight(query: FindFreightQuery): Observable<FreightDetailedResponse> {
    return from(this.findFreightQueryHandler.handle(query));
  }

  public searchDocumentMetadata(query: SearchDocumentMetadataQuery): Promise<DocumentMetadataResponse[]> {
    return this.searchDocumentMetadataQueryHandler.handle(query);
  }
}
