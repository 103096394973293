interface AbstractFreight {
  id: UUID;
  numberOfStops: number;
  nextStopId?: UUID; // rename from nextFreightStopId
  nextStopType?: EStopType; // New, necessary to draw the state of freight (e.g. whether it is going to load or unload). Calculate this value in mapper.
  nextStopStatus?: EStopStatus; // New, necessary to display start or finished button (e.g. whether it is going to load or unload). Calculate this value in mapper.
  reference: string; // Map from shipperFreightM2mReference
  status: EFreightStatus; // Map from FreightStatus
  hasEnoughDocs: boolean; // New, calculate from 'numberOfDeliveryNotes: Number'. If it's less than 1, has not enough docs
  shipperCarrierAssignationId?: UUID; // used for Order de carga
  deliveryNoteInPaper?: boolean;
}

interface AbstractStop {
  id: UUID;
  position: number;
  status: EStopStatus; // NEW enum
  type: EStopType; // enum
  since: Date;
  until: Date;
  address: IAddress;
}

// from DTO FreightBasicResponse
export interface IFreightBasic extends AbstractFreight {
  stops: IStopBasic[];
  drivers: IFreightDriver[]; // get from jorneys[].driver
  vehicles: IFreightVehicles[]; // get from jorneys[].vehicles
  price?: number; // Map from finalPrice
  deliveryNoteInPaperReceived?: boolean;
}

export function getEmptyDefaultFreightBasic(): IFreightBasic {
  return {
    id: '',
    stops: [],
    drivers: [],
    vehicles: [],
    numberOfStops: 0,
    reference: '',
    hasEnoughDocs: true,
    status: EFreightStatus.DEFAULT,
  };
}

// TODO do and use in freight.service and mappers ¿?¿?¿? and card components

// export function getEmptyIfreightBasic(): IFreightBasic {
//   return {

//   }
// }

// from DTO FreightStopResponse
export interface IStopBasic extends AbstractStop {
  // reference: string, // not neccesary yet
}

// from string in FreightBasicResponse ¿?¿?¿?
// from DTO vehicles: VehiclePayload in FreightDetailedResponse
export interface IFreightVehicles {
  id: number; // [1]
  plate: string; // [2]
  partyId: number;
  type: EVehicleType; // [4]
}
export interface IFreightDriver {
  id: number; // [1]
  name: string; // [2]
}

export interface IAddress {
  company?: string; // New, display in address component. Get this value in mapper.
  street: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  coords?: ICoords; // Map from pointPayload. // Note: not needed coords in card (or pseudo-table) component
}

export interface ICoords {
  latitude: number;
  longitude: number;
}
export interface IFreightDetail extends AbstractFreight {
  /*
  id: UUID,
  numberOfStops: number,
  nextStopId: UUID, // rename from nextFreightStopId
  nextStopType: EStopType, // New, necessary to draw the state of freight (e.g. whether it is going to load or unload). Calculate this value in mapper.
  nextStopStatus: EStopStatus; // New, necessary to display start or finished button (e.g. whether it is going to load or unload). Calculate this value in mapper.
  reference: string, // Map from shipperFreightM2mReference
}
*/
  stops: IStopDetail[]; // to map
  drivers?: IFreightDriver[];
  vehicles?: IFreightVehicles[];

  price?: number; // Map from finalPrice

  instructions?: string;
  carrierObservations?: string;

  // map from FreightTransportInfoPayload
  vehicleTypes: EFreightVehicleType[];
  loadingTypes: EFreightLoadingType[];

  hasEnoughDocs: boolean; // New, calculate from numberOfDeliveryNotes

  title: string; // New, calculate this

  deliveryNoteInPaperSentAt?: Date;
  deliveryNoteInPaperReceivedAt?: Date;

  digitizedDeliveryNotes: IDigitizedDeliveryNotes[];

  waste?: boolean; // "transportGoodsType": "WASTE",
  carrierReference?: string;
}

export function getEmptyDefaultFreightDetail(): IFreightDetail {
  return {
    id: '',
    stops: [],
    vehicleTypes: [],
    loadingTypes: [],
    hasEnoughDocs: true,
    title: '',
    numberOfStops: 0,
    reference: '',
    status: EFreightStatus.DEFAULT,
    digitizedDeliveryNotes: [],
  };
}

export interface IStopDetail extends AbstractStop {
  /*
// from AbstractStop =>
  id: UUID;
  position: number;
  type: FreightStopType;
  since: Date;
  until: Date;
  status: EStopStatus // => Calculate this in mapper
  addressPayload: AddressPayload; // => Calculate this, use companyPayload for extract 'company'
  */
  freightId: UUID;
  goodsDescription?: string; // content cargo description
  reference?: string;
  arrivedAt?: Date; // => Use to calculate 'status'
  departedAt?: Date; // => Use to calculate 'status'
  netWeight?: number; // only in LOAD stop ¿?
  packages?: number; // only in LOAD stop ¿?
  unloadedNetWeight?: number; // only in UNLOAD stop
  unloadedPackages?: number; // only in UNLOAD stop
  arrivalDriverAppPoint?: ICoords; // start and finish, same coords
  arrivalTruckPoint?: ICoords; // start and finish, same coords
  reservations?: string;
  acceptanceDriverAppPoint?: ICoords;
  acceptanceTruckPoint?: ICoords;
  acceptanceSignatureImage?: string;
  acceptanceName?: string;
  acceptanceTaxId?: string;
  acceptanceAcceptedAt?: Date;
  sealNumber?: string;
  /*
// not in use from FreightStopResponse
  driverName: string;
  driverMail: string;
  driverPhone: string;
  driverId: number;
  driverTaxId: string;
  operationStartedAt: Date;
  operationFinishedAt: Date;
  tare: number;
  grossWeight: number;

*/
}

export function getEmptyStopDetail(freightId: UUID, position: number): IStopDetail {
  return {
    id: '',
    position: position,
    freightId: freightId,
    status: EStopStatus.UNSTARTED,
  } as IStopDetail;
}

// from DTO VerifyFreightStopGoodsInfoCommand
export interface IStopGoodsInfo {
  stopId: UUID;
  tare: number | null; // modified because a null is being sent
  grossWeight: number | null; // modified because a null is being sent
  netWeight: number;
  packages: number;
  unloadedNetWeight: number;
  unloadedPackages: number;
  goodsDescription: string;
  reservations: string;
  sealNumber: string;
  freightId: UUID;
}

// from DTO DocumentMetadataResponse
export interface IDigitizedDeliveryNotes {
  id: UUID;
  name: string;
  description: string;
  type: EDocumentType;
  contentType: string;
  createdAt: Date;
  aggregateId: UUID;
  freightStopId: UUID;
  companyId: number;
  path: string;
  url?: string;
}

/*************** */

/*************** */

// export declare type UUID = string;
export type UUID = string;

// export declare enum FreightStopType {
export enum EStopType {
  LOAD = 'LOAD',
  UNLOAD = 'UNLOAD',
  UNLOAD_LOAD = 'UNLOAD_LOAD',
  SWAP = 'SWAP',
}

export enum EStopStatus {
  UNSTARTED = 'UNSTARTED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

// export declare enum VehicleType {
// TODO review translations, not all are included, not all included in the translations are necessary
// TODO use vehicle.model (vehicles feature)????
export enum EVehicleType {
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  TRUCK = 'TRUCK',
  VAN = 'VAN',
  SMALL_TRAILER = 'SMALL_TRAILER',
  MEGA_TRAILER = 'MEGA_TRAILER',
  DUO_TRAILER = 'DUO_TRAILER',
  GAS = 'GAS',
  GAS_CARRIER = 'GAS_CARRIER',
  BIODIESEL = 'BIODIESEL',
  BIODIESEL_CARRIER = 'BIODIESEL_CARRIER',
  TRAIN = 'TRAIN',
  TRAIN_CARRIER = 'TRAIN_CARRIER',
  EXCLUDED_CARRIER = 'EXCLUDED_CARRIER',
}

// new, only drivers app
export enum EFreightStatus {
  DEFAULT = 'DEFAULT', // new, default value when object is created

  SENT = 'SENT',

  PENDING = 'PENDING',

  ASSIGNED = 'ASSIGNED',
  ON_ROUTE = 'ON_ROUTE',
  FINISHED = 'FINISHED',

  OFFER_REQUESTED = 'OFFER_REQUESTED',
  OFFER_SENT = 'OFFER_SENT',

  DELETED = 'DELETED', // can't view
  CANCELLED = 'CANCELLED', // can view, can't modify

  ERROR = 'ERROR', // new, is empty or not valid
}

export declare enum EFreightVehicleType {
  CANVAS = 'CANVAS',
  MOBILE_CANVAS = 'MOBILE_CANVAS',
  VAN = 'VAN',
  BOX = 'BOX',
  RIGID_TRUCK = 'RIGID_TRUCK',
  RIGID_CANVAS = 'RIGID_CANVAS',
  REFRIGERATED = 'REFRIGERATED',
  RIGID_REFRIGERATED = 'RIGID_REFRIGERATED',
  BIREFRIGERATED = 'BIREFRIGERATED',
  TRIREFRIGERATED = 'TRIREFRIGERATED',
  BULK = 'BULK',
  DUMP = 'DUMP',
  TANKER = 'TANKER',
  REFRIGERATED_TANKER = 'REFRIGERATED_TANKER',
  CRANE = 'CRANE',
  OTHER = 'OTHER',
}

export declare enum EFreightLoadingType {
  REAR = 'REAR',
  SIDE = 'SIDE',
  TOP = 'TOP',
}

// from DocumentType
// TODO review which ones are really necessary
export declare enum EDocumentType {
  TAX_AUTHORITIES_PAYMENT_CERTIFICATE = 'TAX_AUTHORITIES_PAYMENT_CERTIFICATE',
  TAX_AUTHORITIES_PAYMENT_CERTIFICATE_OKCARGO = 'TAX_AUTHORITIES_PAYMENT_CERTIFICATE_OKCARGO',
  SOCIAL_SECURITY_PAYMENT_CERTIFICATE = 'SOCIAL_SECURITY_PAYMENT_CERTIFICATE',
  BANK_OWNERSHIP_CERTIFICATE = 'BANK_OWNERSHIP_CERTIFICATE',
  GOODS_ASSURANCE = 'GOODS_INSURANCE',
  EUROPEAN_LICENSE = 'EUROPEAN_LICENSE',
  CIVIL_RESPONSABILITY_ASSURANCE = 'RLC',
  ACTIVITY_LICENSE = 'ACTIVITY_LICENSE',
  DELIVERY_NOTE = 'DELIVERY_NOTE',
  FREIGHT_EXPORT = 'FREIGHT_EXPORT',
  EMISSION_JOURNEY = 'EMISSION_JOURNEY',
  DELIVERY_NOTES_EXPORT = 'DELIVERY_NOTES_EXPORT',
  INVOICING_REPORT = 'INVOICING_REPORT',
  FREIGHTS_WITH_ISSUES_REPORT = 'FREIGHTS_WITH_ISSUES_REPORT',
  APAAC_ASSIGNATION_REPORT = 'APAAC_ASSIGNATION_REPORT',
  PAST_DUE_DEBT_INVOICES_REPORT = 'PAST_DUE_DEBT_INVOICES_REPORT',
  NONE = 'NONE',
}

/*** */

// export declare enum FreightStatus {
export enum EFreightStatusOLD {
  // OFFER_REQUESTED = "OFFER_REQUESTED",
  // OFFER_SENT = "OFFER_SENT",
  // OFFER_REJECTED = "OFFER_REJECTED",
  // PENDING = "PENDING",
  ASSIGNED = 'ASSIGNED', // asignado, no iniciado
  CANCELLED_BY_CARRIER = 'CANCELLED_BY_CARRIER', // puedo ver, pero no puede interactuar
  CANCELLED_BY_SHIPPER = 'CANCELLED_BY_SHIPPER', // idem
  ACCEPTED = 'ACCEPTED', // asignado y aceptado
  REJECTED = 'REJECTED', // asignado y rechazado
  // STARTED = "STARTED", // no se usa, pero es ir hacia la carga
  ARRIVED = 'ARRIVED', // he llegado a una parada, he marcado que he llegado pero no he marcado que he salido
  // WORKING_START = "WORKING_START",
  // WORKING_END = "WORKING_END",
  ON_ROUTE = 'ON_ROUTE', // he marcado la salida de una parada y voy a la siguiente
  FINISHED = 'FINISHED', // acabado todas las paradas y marcado la finalización
  FINISHED_BY_CARRIER = 'FINISHED_BY_CARRIER', // no se ha usado la app, lo marca el cargador
  FINISHED_BY_SHIPPER = 'FINISHED_BY_SHIPPER', // no se ha usado la app, lo marca tráfico
  FINISHED_WITH_ISSUE = 'FINISHED_WITH_ISSUE', // hay una incidencia, no se puede facturar
  FINISHED_WITH_ISSUE_SOLVED = 'FINISHED_WITH_ISSUE_SOLVED', // siguiente paso del estado anterior
  // NO_SHOW_BY_CARRIER = "NO_SHOW_BY_CARRIER",
  // NO_SHOW_BY_SHIPPER = "NO_SHOW_BY_SHIPPER",
  DELETED = 'DELETED',
}
