import { HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { DocumentMetadataResponse } from '@okcargo/query-processor';
import { firstValueFrom, Observable } from 'rxjs';
import { ICompanyDocument } from 'src/app/company/domain/company.model';
import { ApiService } from './api.service';
import { HttpService } from './http.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadDocumentService {
  private readonly apiService = inject(ApiService);
  private readonly platform = inject(PlatformService);
  private readonly httpService = inject(HttpService);

  constructor() {}

  // TODO review, documents doenloaded are ok or not???????
  public getDownloadDocumentURL(document: ICompanyDocument | DocumentMetadataResponse): string {
    // interface in query-processor????
    const query = {
      documentId: document.id,
      freightId: null, // Is it necessary??
      companyId: null, // Is it necessary??
    };
    return (
      '/services/query-processor/api/queries/download?name=GenericDownloadDocumentQuery&payload=' +
      encodeURI(JSON.stringify(query))
    );
  }

  public async downloadFile(endpoint: string, filename: string = 'downloaded_file'): Promise<void> {
    const reqOpts = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
    };
    const isExternalUrl = endpoint.startsWith('http://') || endpoint.startsWith('https://');

    const response: HttpResponse<Blob> = await firstValueFrom(
      (isExternalUrl
        ? this.httpService.get(endpoint)
        : this.apiService.get(endpoint, reqOpts)) as unknown as Observable<HttpResponse<Blob>>,
    );

    let mime = 'application/pdf';
    if (response.body) {
      filename = this.fixFilenameExtension(filename, response.body.type);
      mime = response.body.type;
    }
    if (this.platform.isHybrid) {
      await this.saveFileToDevice(response.body as Blob, filename, mime);
    } else {
      this.saveFile(response.body as Blob, filename);
    }
  }

  private saveFile(data: Blob | null, filename: string): void {
    if (!data) {
      // TODO implement this
      console.error('No data received for the file.');
      return;
    }
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private async saveFileToDevice(blob: Blob, filename: string, mime: string): Promise<void> {
    try {
      const base64Data = await this.convertBlobToBase64(blob);
      const base64 = base64Data.split(',')[1];
      await Filesystem.writeFile({
        path: filename,
        data: base64,
        directory: Directory.Documents,
      });

      const filePath = await Filesystem.getUri({
        directory: Directory.Documents,
        path: filename,
      });

      try {
        const fileOpenerOptions: FileOpenerOptions = {
          filePath: filePath.uri,
          contentType: mime,
          openWithDefault: true,
        };
        await FileOpener.open(fileOpenerOptions);
      } catch (e) {
        // TODO implement this
        console.warn('Error opening file', e);
      }
    } catch (error) {
      // TODO implement this
      console.error('Error writing file', error);
    }
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private fixFilenameExtension(filename: string, mime: string): string {
    const newExtension = {
      'image/jpeg': 'jpg',
      'application/pdf': 'pdf',
    }[mime];

    if (!newExtension) {
      return filename;
    }

    const nameWithoutExtension = filename.substring(0, filename.lastIndexOf('.')) || filename;
    console.log('nameWithoutExtension', nameWithoutExtension + '.' + newExtension);
    return nameWithoutExtension + newExtension;
  }
}
