import { inject, Injectable } from '@angular/core';
import { Query, QueryRepository } from '@okcargo/query-processor';
import { firstValueFrom } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class BaseQueryRepository implements QueryRepository {
  private readonly apiService = inject(ApiService);

  private readonly _baseUrl = '/services/query-processor/api/queries';

  constructor() {}

  execute(name: string, query: Query): Promise<any> {
    return firstValueFrom(
      // this.apiService.get<any>(
      this.apiService.get(`${this._baseUrl}?name=${name}&payload=${encodeURI(JSON.stringify(query))}`, {
        observe: 'body',
      }),
    );
  }
}
