import {
  FreightBasicResponse,
  FreightDetailedResponse,
  FreightStatus,
  FreightTransportGoodsType,
} from '@okcargo/query-processor';
import {
  EFreightLoadingType,
  EFreightStatus,
  EFreightVehicleType,
  EStopStatus,
  EStopType,
  EVehicleType,
  IFreightBasic,
  IFreightDetail,
  IFreightDriver,
  IFreightVehicles,
  IStopBasic,
  IStopDetail,
} from '../domain/freight.model';

export function transformToIFreightBasic(
  freightBasicResponse: FreightBasicResponse,
  freight: IFreightBasic,
): IFreightBasic {
  freight = {
    ...freight,
    id: freightBasicResponse.id,
    numberOfStops: freightBasicResponse.numberOfStops,
    reference: freightBasicResponse.shipperFreightM2mReference,
    shipperCarrierAssignationId: freightBasicResponse.shipperCarrierAssignationId,
    price: freightBasicResponse.finalPrice ? freightBasicResponse.finalPrice : undefined,
  };

  freightBasicResponse.stops.forEach((stopResponse) => {
    const stop: IStopBasic = {
      id: stopResponse.id,
      position: stopResponse.position,
      type: stopResponse.stopType as unknown as EStopType,
      status: stopResponse.stopStatus as unknown as EStopStatus,
      since: new Date(stopResponse.since),
      until: new Date(stopResponse.until),
      address: {
        company: stopResponse.companyName,
        street: stopResponse.addressPayload.street,
        city: stopResponse.addressPayload.city,
        postalCode: stopResponse.addressPayload.postalCode,
        state: stopResponse.addressPayload.state,
        country: stopResponse.addressPayload.country,
      },
      // reference: stopResponse.reference, // not necessary yet
    };

    freight.stops.push(stop);
  });

  // TODO can there be malformed freights where the nextStopId is not any of the stop ids?
  if (
    freightBasicResponse.nextStopId !== undefined &&
    freight.stops.length > 0 &&
    freight.stops.some((stop) => stop.id === freightBasicResponse.nextStopId)
  ) {
    freight.nextStopId = freightBasicResponse.nextStopId;
    const nextStop = freight.stops.find((stop) => stop.id === freight.nextStopId);
    if (nextStop) {
      freight.nextStopType = nextStop.type;
      freight.nextStopStatus = nextStop.status;
    }
  }

  if (freightBasicResponse.journeys.length > 0) {
    freightBasicResponse.journeys.forEach((journeyResponse) => {
      if (journeyResponse.driver !== undefined) {
        const driver: IFreightDriver = {
          id: journeyResponse.driver.id,
          name: journeyResponse.driver.driverName,
        };
        freight.drivers?.push(driver);
      }
      if (journeyResponse.vehicles !== undefined) {
        journeyResponse.vehicles.forEach((vehicleResponse) => {
          // If there is no id then the vehicle is not valid, ignore it
          if (vehicleResponse.id !== undefined) {
            const vehicle: IFreightVehicles = {
              id: vehicleResponse.id,
              plate: vehicleResponse.plateNumber,
              partyId: vehicleResponse.companyId,
              type: vehicleResponse.type as unknown as EVehicleType,
            };
            freight.vehicles?.push(vehicle);
          }
        });
      }
    });
  }

  if (freightBasicResponse.status !== undefined)
    freight.status = transformToEFreightStatus(freightBasicResponse.status);

  if (
    (freightBasicResponse.numberOfDeliveryNotes === undefined || freightBasicResponse.numberOfDeliveryNotes === 0) &&
    freight.status !== EFreightStatus.CANCELLED &&
    (!freightBasicResponse.shipperInvoicePaid || !freightBasicResponse.collaboratorInvoicePaid)
  ) {
    freight.hasEnoughDocs = false;
  }

  if (freightBasicResponse.deliveryNoteInPaperNeeded !== undefined) {
    freight.deliveryNoteInPaper = freightBasicResponse.deliveryNoteInPaperNeeded;
    if (freightBasicResponse.deliveryNoteInPaperNeeded !== undefined) {
      freight.deliveryNoteInPaperReceived = freightBasicResponse.deliveryNoteInPaperReceived;
    }
  }

  // TODO maybe this is a Maybe this is a temporary solution because there were times when the status of the last stop was not consistent with that of the freight
  if (freight.stops.length > 0) {
    if (freight.status !== EFreightStatus.FINISHED) {
      if (freight.stops[freight.stops.length - 1].status === EStopStatus.FINISHED) {
        freight.status = EFreightStatus.FINISHED;
      }
    }
  }

  return freight;
}

export function transformToIFreightDetail(
  freightDetailedResponse: FreightDetailedResponse,
  freight: IFreightDetail,
): IFreightDetail {
  freight = {
    ...freight,
    id: freightDetailedResponse.id,
    numberOfStops: freightDetailedResponse.freightStopResponses.length,
    reference: freightDetailedResponse.shipperFreightM2mReference,
    nextStopId: freightDetailedResponse.nextFreightStopId,
  };

  freightDetailedResponse.freightStopResponses.forEach((stopResponse) => {
    let stop: IStopDetail = {
      id: stopResponse.id,
      freightId: stopResponse.freightId,
      position: stopResponse.position,
      type: stopResponse.type as unknown as EStopType,
      status: EStopStatus.UNSTARTED, // calculate this with arrivedAt and departedAt
      since: new Date(stopResponse.since),
      until: new Date(stopResponse.until),
      address: {
        company: stopResponse.companyPayload.name,
        street: stopResponse.addressPayload.street,
        city: stopResponse.addressPayload.city,
        postalCode: stopResponse.addressPayload.postalCode,
        state: stopResponse.addressPayload.state,
        country: stopResponse.addressPayload.country,
        coords: stopResponse.addressPayload.pointPayload,
      },
    };
    if (stopResponse.goodsDescription !== undefined) stop.goodsDescription = stopResponse.goodsDescription;
    if (stopResponse.reference !== undefined) stop.reference = stopResponse.reference;
    if (stopResponse.arrivedAt !== undefined) stop.arrivedAt = new Date(stopResponse.arrivedAt);
    if (stopResponse.departedAt !== undefined) stop.departedAt = new Date(stopResponse.departedAt);
    if (stopResponse.netWeight !== undefined) stop.netWeight = stopResponse.netWeight;
    if (stopResponse.packages !== undefined) stop.packages = stopResponse.packages;
    if (stopResponse.unloadedNetWeight !== undefined) stop.unloadedNetWeight = stopResponse.unloadedNetWeight;
    if (stopResponse.unloadedPackages !== undefined) stop.unloadedPackages = stopResponse.unloadedPackages;
    if (stopResponse.arrivalDriverAppPoint !== undefined)
      stop.arrivalDriverAppPoint = stopResponse.arrivalDriverAppPoint;
    if (stopResponse.arrivalTruckPoint !== undefined) stop.arrivalTruckPoint = stopResponse.arrivalTruckPoint;
    if (stopResponse.reservations !== undefined) stop.reservations = stopResponse.reservations;
    if (stopResponse.acceptanceDriverAppPoint !== undefined)
      stop.acceptanceDriverAppPoint = stopResponse.acceptanceDriverAppPoint;
    if (stopResponse.acceptanceTruckPoint !== undefined) stop.acceptanceTruckPoint = stopResponse.acceptanceTruckPoint;
    if (stopResponse.acceptanceSignatureImage !== undefined)
      stop.acceptanceSignatureImage = stopResponse.acceptanceSignatureImage;
    if (stopResponse.acceptanceName !== undefined) stop.acceptanceName = stopResponse.acceptanceName;
    if (stopResponse.acceptanceTaxId !== undefined) stop.acceptanceTaxId = stopResponse.acceptanceTaxId;
    if (stopResponse.acceptanceAcceptedAt !== undefined) stop.acceptanceAcceptedAt = stopResponse.acceptanceAcceptedAt;
    if (stopResponse.sealNumber !== undefined) stop.sealNumber = stopResponse.sealNumber;
    if (stopResponse.arrivedAt !== undefined) {
      if (stopResponse.departedAt !== undefined) {
        stop.status = EStopStatus.FINISHED;
      } else {
        stop.status = EStopStatus.STARTED;
      }
    }
    freight.stops.push(stop);
  });

  if (freightDetailedResponse.status !== undefined)
    freight.status = transformToEFreightStatus(freightDetailedResponse.status);
  if (freightDetailedResponse.instructions !== undefined) freight.instructions = freightDetailedResponse.instructions;
  if (freightDetailedResponse.carrierObservations !== undefined)
    freight.carrierObservations = freightDetailedResponse.carrierObservations;
  if (freightDetailedResponse.shipperCarrierAssignationId !== undefined)
    freight.shipperCarrierAssignationId = freightDetailedResponse.shipperCarrierAssignationId;

  if (Array.isArray(freightDetailedResponse.transportInfoPayload?.vehicleTypes)) {
    freightDetailedResponse.transportInfoPayload.vehicleTypes.forEach((vehicle) => {
      freight.vehicleTypes.push(vehicle as unknown as EFreightVehicleType);
    });
    freightDetailedResponse.transportInfoPayload.loadingTypes.forEach((vehicle) => {
      freight.loadingTypes.push(vehicle as unknown as EFreightLoadingType);
    });
    if (freightDetailedResponse.transportInfoPayload?.transportGoodsType === FreightTransportGoodsType.WASTE) {
      freight.waste = true;
    }
  }

  // TODO review this, how to get the price
  if (
    Array.isArray(freightDetailedResponse.freightAssignationResponses) &&
    freightDetailedResponse.freightAssignationResponses.length > 0 &&
    freightDetailedResponse.freightAssignationResponses[0].finalPrice
  ) {
    freight.price = freightDetailedResponse.freightAssignationResponses[0].finalPrice;
  }

  // TODO review this
  if (
    (freightDetailedResponse.numberOfDeliveryNotes === undefined ||
      freightDetailedResponse.numberOfDeliveryNotes === 0) &&
    freight.status !== EFreightStatus.CANCELLED
  ) {
    freight.hasEnoughDocs = false;
  }

  if (freightDetailedResponse.deliveryNoteInPaper !== undefined) {
    freight.deliveryNoteInPaper = freightDetailedResponse.deliveryNoteInPaper;
  }

  if (Array.isArray(freightDetailedResponse.vehicles)) {
    freight.vehicles = [];
    freightDetailedResponse.vehicles.forEach((vehicleResponse) => {
      // If there is no id then the vehicle is not valid, ignore
      if (vehicleResponse.id !== undefined) {
        const vehicle: IFreightVehicles = {
          id: vehicleResponse.id,
          plate: vehicleResponse.plateNumber,
          partyId: vehicleResponse.partyId,
          type: vehicleResponse.vehicleType as unknown as EVehicleType,
        };
        freight.vehicles?.push(vehicle);
      }
    });
  }

  if (freightDetailedResponse.driver !== undefined) {
    freight.drivers = [
      {
        id: freightDetailedResponse.driver.id,
        name: freightDetailedResponse.driver.driverName,
      },
    ];
  }

  // TODO only when the freight was uploaded by user
  if (freightDetailedResponse.carrierFreightM2mReference !== undefined) {
    freight.carrierReference = freightDetailedResponse.carrierFreightM2mReference;
  }

  if (freight.stops.length > 0) {
    freight.nextStopType = freight.stops.find((stop) => stop.id === freight.nextStopId)?.type;
    freight.nextStopStatus = freight.stops.find((stop) => stop.id === freight.nextStopId)?.status;

    // TODO Maybe this is a temporary solution because there were times when the status of the last stop was not consistent with that of the freight
    if (freight.status !== EFreightStatus.FINISHED) {
      if (freight.stops[freight.stops.length - 1].status === EStopStatus.FINISHED) {
        freight.status = EFreightStatus.FINISHED;
      }
    }

    // HOTFIX if status is FINISHED, mark all stops as FINISHED
    // TODO review
    if (freight.status === EFreightStatus.FINISHED) {
      freight.stops.forEach((stop) => {
        stop.status = EStopStatus.FINISHED;
      });
    }
  }

  // TODO review this, get a more descriptive title
  // TODO translate outside, or maybe asign title in component
  freight.title = freight.reference;

  return freight;
}

export function transformToEFreightStatus(freightStatusResponse: FreightStatus): EFreightStatus {
  switch (freightStatusResponse) {
    case FreightStatus.PENDING:
      return EFreightStatus.PENDING;

    case FreightStatus.OFFER_REQUESTED: // ??? seen in freights in progress // sin precio asignado
      return EFreightStatus.OFFER_REQUESTED;

    case FreightStatus.OFFER_SENT: // ??? seen in freights in progress // precio asignado, pero sin aún sin aceptar
      return EFreightStatus.OFFER_SENT;

    case FreightStatus.ACCEPTED: // precio aceptado ¿por tráfico y no por el transportista según como estaba la actual torre de control?
    case FreightStatus.ASSIGNED:
      return EFreightStatus.ASSIGNED;

    case FreightStatus.STARTED:
    case FreightStatus.ARRIVED:
    case FreightStatus.WORKING_START:
    case FreightStatus.WORKING_END:
    case FreightStatus.ON_ROUTE:
      return EFreightStatus.ON_ROUTE;

    case FreightStatus.FINISHED:
    case FreightStatus.FINISHED_BY_CARRIER:
    case FreightStatus.FINISHED_BY_SHIPPER:
    case FreightStatus.FINISHED_WITH_ISSUE:
    case FreightStatus.FINISHED_WITH_ISSUE_SOLVED:
      return EFreightStatus.FINISHED;

    case FreightStatus.CANCELLED_BY_CARRIER:
    case FreightStatus.CANCELLED_BY_SHIPPER:
    case FreightStatus.OFFER_REJECTED:
      return EFreightStatus.CANCELLED;

    case FreightStatus.REJECTED:
    case FreightStatus.NO_SHOW_BY_CARRIER:
    case FreightStatus.NO_SHOW_BY_SHIPPER:
    case FreightStatus.DELETED:
    // TODO for now, DRAFT is not being controlled
    default:
      return EFreightStatus.DELETED;
  }
}
